// Do not edit this file manually. Auto-generated by geist 'pnpm generate-icons-16'.

import _AcronymGif from './sprites/acronym-gif.svg';
import _AcronymJpg from './sprites/acronym-jpg.svg';
import _AcronymApi from './sprites/acronym-api.svg';
import _AcronymPage from './sprites/acronym-page.svg';
import _AcronymPpr from './sprites/acronym-ppr.svg';
import _AcronymSsr from './sprites/acronym-ssr.svg';
import _AcronymSsg from './sprites/acronym-ssg.svg';
import _AcronymHttp from './sprites/acronym-http.svg';
import _AcronymMarkdown from './sprites/acronym-markdown.svg';
import _AcronymSvg from './sprites/acronym-svg.svg';
import _AcronymCsv from './sprites/acronym-csv.svg';
import _AcronymTs from './sprites/acronym-ts.svg';
import _AcronymJs from './sprites/acronym-js.svg';
import _FaceHappy from './sprites/face-happy.svg';
import _FacePlus from './sprites/face-plus.svg';
import _FaceSmile from './sprites/face-smile.svg';
import _Accessibility from './sprites/accessibility.svg';
import _AccessibilityUnread from './sprites/accessibility-unread.svg';
import _User from './sprites/user.svg';
import _UserCheck from './sprites/user-check.svg';
import _UserKey from './sprites/user-key.svg';
import _UserKeyFill from './sprites/user-key-fill.svg';
import _UserPlus from './sprites/user-plus.svg';
import _UserMinus from './sprites/user-minus.svg';
import _UserSettings from './sprites/user-settings.svg';
import _UserLink from './sprites/user-link.svg';
import _UserScreen from './sprites/user-screen.svg';
import _Eye from './sprites/eye.svg';
import _EyeDashed from './sprites/eye-dashed.svg';
import _EyeOff from './sprites/eye-off.svg';
import _UserCross from './sprites/user-cross.svg';
import _Users from './sprites/users.svg';
import _ThumbUp from './sprites/thumb-up.svg';
import _ThumbDown from './sprites/thumb-down.svg';
import _Heart from './sprites/heart.svg';
import _HeartFill from './sprites/heart-fill.svg';
import _FaceUnhappy from './sprites/face-unhappy.svg';
import _FaceMeh from './sprites/face-meh.svg';
import _FaceSad from './sprites/face-sad.svg';
import _EyeAllSeeing from './sprites/eye-all-seeing.svg';
import _DeviceDesktop from './sprites/device-desktop.svg';
import _DeviceDesktopAlternate from './sprites/device-desktop-alternate.svg';
import _DeviceDesktopAlternateSmall from './sprites/device-desktop-alternate-small.svg';
import _DevicePhone from './sprites/device-phone.svg';
import _DeviceTablet from './sprites/device-tablet.svg';
import _Sun from './sprites/sun.svg';
import _SunSmall from './sprites/sun-small.svg';
import _SunAlternate from './sprites/sun-alternate.svg';
import _MoonAlternate from './sprites/moon-alternate.svg';
import _Bug from './sprites/bug.svg';
import _Star from './sprites/star.svg';
import _Lightning from './sprites/lightning.svg';
import _StarFill from './sprites/star-fill.svg';
import _Sparkles from './sprites/sparkles.svg';
import _CloudUpload from './sprites/cloud-upload.svg';
import _PlantSprout from './sprites/plant-sprout.svg';
import _CloudDownload from './sprites/cloud-download.svg';
import _Cloud from './sprites/cloud.svg';
import _Moon from './sprites/moon.svg';
import _MoonSmall from './sprites/moon-small.svg';
import _Home from './sprites/home.svg';
import _GoldenGate from './sprites/golden-gate.svg';
import _Location from './sprites/location.svg';
import _Route from './sprites/route.svg';
import _Gps from './sprites/gps.svg';
import _Buildings from './sprites/buildings.svg';
import _Store from './sprites/store.svg';
import _Phone from './sprites/phone.svg';
import _Message from './sprites/message.svg';
import _MessageTyping from './sprites/message-typing.svg';
import _Kv from './sprites/kv.svg';
import _EdgeConfig from './sprites/edge-config.svg';
import _FirewallGlobe from './sprites/firewall-globe.svg';
import _FirewallFlame from './sprites/firewall-flame.svg';
import _FirewallCheck from './sprites/firewall-check.svg';
import _AiSdk from './sprites/ai-sdk.svg';
import _Postgres from './sprites/postgres.svg';
import _Blob from './sprites/blob.svg';
import _FunctionEdge from './sprites/function-edge.svg';
import _FunctionMiddleware from './sprites/function-middleware.svg';
import _Gauge from './sprites/gauge.svg';
import _PreviewEye from './sprites/preview-eye.svg';
import _PreviewDocument from './sprites/preview-document.svg';
import _SecureConnection from './sprites/secure-connection.svg';
import _WindowGlobe from './sprites/window-globe.svg';
import _ArrowGlobe from './sprites/arrow-globe.svg';
import _Isr from './sprites/isr.svg';
import _Api from './sprites/api.svg';
import _Monorepo from './sprites/monorepo.svg';
import _Repositories from './sprites/repositories.svg';
import _Spiral from './sprites/spiral.svg';
import _Puzzle from './sprites/puzzle.svg';
import _WindowVariable from './sprites/window-variable.svg';
import _Variable from './sprites/variable.svg';
import _Lambda from './sprites/lambda.svg';
import _LayoutShift from './sprites/layout-shift.svg';
import _LayoutShiftUnread from './sprites/layout-shift-unread.svg';
import _Function from './sprites/function.svg';
import _ShieldGlobe from './sprites/shield-globe.svg';
import _GlobeBox from './sprites/globe-box.svg';
import _Webhook from './sprites/webhook.svg';
import _FunctionNode from './sprites/function-node.svg';
import _FunctionPython from './sprites/function-python.svg';
import _FunctionRuby from './sprites/function-ruby.svg';
import _FunctionN from './sprites/function-n.svg';
import _FunctionGo from './sprites/function-go.svg';
import _TextFormat from './sprites/text-format.svg';
import _TextTitle from './sprites/text-title.svg';
import _TextItalic from './sprites/text-italic.svg';
import _TextStrikethrough from './sprites/text-strikethrough.svg';
import _TextHeading from './sprites/text-heading.svg';
import _TextBold from './sprites/text-bold.svg';
import _TextUppercase from './sprites/text-uppercase.svg';
import _Email from './sprites/email.svg';
import _Cursor from './sprites/cursor.svg';
import _CursorHead from './sprites/cursor-head.svg';
import _CursorClick from './sprites/cursor-click.svg';
import _CodeBracket from './sprites/code-bracket.svg';
import _LoaderCircle from './sprites/loader-circle.svg';
import _LoaderDots from './sprites/loader-dots.svg';
import _TerminalWindow from './sprites/terminal-window.svg';
import _Terminal from './sprites/terminal.svg';
import _Tabs from './sprites/tabs.svg';
import _Window from './sprites/window.svg';
import _Display from './sprites/display.svg';
import _Layout from './sprites/layout.svg';
import _SidebarLeft from './sprites/sidebar-left.svg';
import _SidebarRight from './sprites/sidebar-right.svg';
import _Header from './sprites/header.svg';
import _Footer from './sprites/footer.svg';
import _LayoutDashed from './sprites/layout-dashed.svg';
import _Notification from './sprites/notification.svg';
import _Toolbar from './sprites/toolbar.svg';
import _StrokeWeight from './sprites/stroke-weight.svg';
import _FillSquare from './sprites/fill-square.svg';
import _BezierCurve from './sprites/bezier-curve.svg';
import _Glyphs from './sprites/glyphs.svg';
import _Languages from './sprites/languages.svg';
import _SettingsGear from './sprites/settings-gear.svg';
import _SettingsGearFill from './sprites/settings-gear-fill.svg';
import _SettingsSliders from './sprites/settings-sliders.svg';
import _SettingsSlider from './sprites/settings-slider.svg';
import _ToggleOffAlt from './sprites/toggle-off-alt.svg';
import _ToggleOff from './sprites/toggle-off.svg';
import _ToggleOn from './sprites/toggle-on.svg';
import _ToggleOnAlt from './sprites/toggle-on-alt.svg';
import _ToggleOffAltUnread from './sprites/toggle-off-alt-unread.svg';
import _ToggleOnAltUnread from './sprites/toggle-on-alt-unread.svg';
import _MoreHorizontal from './sprites/more-horizontal.svg';
import _MoreVertical from './sprites/more-vertical.svg';
import _Command from './sprites/command.svg';
import _Asterisk from './sprites/asterisk.svg';
import _Diamond from './sprites/diamond.svg';
import _AsteriskSmall from './sprites/asterisk-small.svg';
import _DiamondSmall from './sprites/diamond-small.svg';
import _Hash from './sprites/hash.svg';
import _Percentage from './sprites/percentage.svg';
import _Plus from './sprites/plus.svg';
import _Cross from './sprites/cross.svg';
import _CrossCircle from './sprites/cross-circle.svg';
import _CrossSmall from './sprites/cross-small.svg';
import _PlusCircle from './sprites/plus-circle.svg';
import _PlusSquareSmall from './sprites/plus-square-small.svg';
import _MinusSquareSmall from './sprites/minus-square-small.svg';
import _Minus from './sprites/minus.svg';
import _MinusCircle from './sprites/minus-circle.svg';
import _Shift from './sprites/shift.svg';
import _Option from './sprites/option.svg';
import _Tab from './sprites/tab.svg';
import _Backspace from './sprites/backspace.svg';
import _SlashForward from './sprites/slash-forward.svg';
import _SlashBack from './sprites/slash-back.svg';
import _CrossCircleFill from './sprites/cross-circle-fill.svg';
import _Router from './sprites/router.svg';
import _Servers from './sprites/servers.svg';
import _Rss from './sprites/rss.svg';
import _Delta from './sprites/delta.svg';
import _NetworkDevice from './sprites/network-device.svg';
import _Code from './sprites/code.svg';
import _GitBranch from './sprites/git-branch.svg';
import _GitBranchPlus from './sprites/git-branch-plus.svg';
import _GitBranchMinus from './sprites/git-branch-minus.svg';
import _BeakerFlask from './sprites/beaker-flask.svg';
import _CanaryBird from './sprites/canary-bird.svg';
import _GitMerge from './sprites/git-merge.svg';
import _Connection from './sprites/connection.svg';
import _GitPullRequest from './sprites/git-pull-request.svg';
import _GitCommit from './sprites/git-commit.svg';
import _CodeWrap from './sprites/code-wrap.svg';
import _CodeBlock from './sprites/code-block.svg';
import _Layers from './sprites/layers.svg';
import _DataPoint from './sprites/data-point.svg';
import _DataPointLow from './sprites/data-point-low.svg';
import _DataPointMedium from './sprites/data-point-medium.svg';
import _Cpu from './sprites/cpu.svg';
import _Codepen from './sprites/codepen.svg';
import _Database from './sprites/database.svg';
import _FloppyDisk from './sprites/floppy-disk.svg';
import _Play from './sprites/play.svg';
import _PlayFill from './sprites/play-fill.svg';
import _Pause from './sprites/pause.svg';
import _SkipBack from './sprites/skip-back.svg';
import _SkipBackFill from './sprites/skip-back-fill.svg';
import _SkipForward from './sprites/skip-forward.svg';
import _SkipForwardFill from './sprites/skip-forward-fill.svg';
import _StopCircle from './sprites/stop-circle.svg';
import _Rewind10Seconds from './sprites/rewind-10-seconds.svg';
import _Forward10Seconds10 from './sprites/forward-10-seconds-10.svg';
import _PlayCircle from './sprites/play-circle.svg';
import _Fullscreen from './sprites/fullscreen.svg';
import _FullscreenClose from './sprites/fullscreen-close.svg';
import _ClosedCaptions from './sprites/closed-captions.svg';
import _Shareplay from './sprites/shareplay.svg';
import _PictureInPicture from './sprites/picture-in-picture.svg';
import _Video from './sprites/video.svg';
import _Webcam from './sprites/webcam.svg';
import _WebcamOff from './sprites/webcam-off.svg';
import _Music from './sprites/music.svg';
import _MusicalNotes from './sprites/musical-notes.svg';
import _Microphone from './sprites/microphone.svg';
import _MicrophoneOff from './sprites/microphone-off.svg';
import _Speaker from './sprites/speaker.svg';
import _SpeakerFill from './sprites/speaker-fill.svg';
import _SpeakerOff from './sprites/speaker-off.svg';
import _SpeakerOffFill from './sprites/speaker-off-fill.svg';
import _SpeakerVolumeQuiet from './sprites/speaker-volume-quiet.svg';
import _SpeakerVolumeQuietFill from './sprites/speaker-volume-quiet-fill.svg';
import _SpeakerVolumeLoud from './sprites/speaker-volume-loud.svg';
import _SpeakerVolumeLoudFill from './sprites/speaker-volume-loud-fill.svg';
import _Crop from './sprites/crop.svg';
import _Image from './sprites/image.svg';
import _Camera from './sprites/camera.svg';
import _FolderClosed from './sprites/folder-closed.svg';
import _FolderPlus from './sprites/folder-plus.svg';
import _FolderMinus from './sprites/folder-minus.svg';
import _File from './sprites/file.svg';
import _FileZip from './sprites/file-zip.svg';
import _FileText from './sprites/file-text.svg';
import _Invoice from './sprites/invoice.svg';
import _FolderOpen from './sprites/folder-open.svg';
import _Key from './sprites/key.svg';
import _MagnifyingGlass from './sprites/magnifying-glass.svg';
import _MagnifyingGlassSmall from './sprites/magnifying-glass-small.svg';
import _MagnifyingGlassPlus from './sprites/magnifying-glass-plus.svg';
import _MagnifyingGlassMinus from './sprites/magnifying-glass-minus.svg';
import _PaperAirplane from './sprites/paper-airplane.svg';
import _KeyOld from './sprites/key-old.svg';
import _Calendar from './sprites/calendar.svg';
import _Pencil from './sprites/pencil.svg';
import _Pen from './sprites/pen.svg';
import _PencilEdit from './sprites/pencil-edit.svg';
import _Inbox from './sprites/inbox.svg';
import _InboxUnread from './sprites/inbox-unread.svg';
import _Archive from './sprites/archive.svg';
import _Pin from './sprites/pin.svg';
import _Paperclip from './sprites/paperclip.svg';
import _BookClosed from './sprites/book-closed.svg';
import _BookOpen from './sprites/book-open.svg';
import _Presentation from './sprites/presentation.svg';
import _Envelope from './sprites/envelope.svg';
import _Tag from './sprites/tag.svg';
import _Notebook from './sprites/notebook.svg';
import _Notes from './sprites/notes.svg';
import _Calculator from './sprites/calculator.svg';
import _Clipboard from './sprites/clipboard.svg';
import _Bookmark from './sprites/bookmark.svg';
import _Fulcrum from './sprites/fulcrum.svg';
import _Prism from './sprites/prism.svg';
import _PrismColor from './sprites/prism-color.svg';
import _Compass from './sprites/compass.svg';
import _Filter from './sprites/filter.svg';
import _FilterSmall from './sprites/filter-small.svg';
import _Wrench from './sprites/wrench.svg';
import _Trash from './sprites/trash.svg';
import _Flag from './sprites/flag.svg';
import _FlagPriority from './sprites/flag-priority.svg';
import _Bell from './sprites/bell.svg';
import _BellSmall from './sprites/bell-small.svg';
import _BellOff from './sprites/bell-off.svg';
import _Briefcase from './sprites/briefcase.svg';
import _CreditCard from './sprites/credit-card.svg';
import _Headset from './sprites/headset.svg';
import _Cart from './sprites/cart.svg';
import _Link from './sprites/link.svg';
import _Linked from './sprites/linked.svg';
import _Droplet from './sprites/droplet.svg';
import _Shield from './sprites/shield.svg';
import _ShieldSmall from './sprites/shield-small.svg';
import _ShieldCheck from './sprites/shield-check.svg';
import _Anchor from './sprites/anchor.svg';
import _Hook from './sprites/hook.svg';
import _ShieldOff from './sprites/shield-off.svg';
import _Box from './sprites/box.svg';
import _LockClosed from './sprites/lock-closed.svg';
import _LockClosedSmall from './sprites/lock-closed-small.svg';
import _LockOpenSmall from './sprites/lock-open-small.svg';
import _LockOpen from './sprites/lock-open.svg';
import _Coins from './sprites/coins.svg';
import _Clock from './sprites/clock.svg';
import _ClockSmall from './sprites/clock-small.svg';
import _ClockRewind from './sprites/clock-rewind.svg';
import _ClockDashed from './sprites/clock-dashed.svg';
import _Stopwatch from './sprites/stopwatch.svg';
import _StopwatchUnread from './sprites/stopwatch-unread.svg';
import _StopwatchFast from './sprites/stopwatch-fast.svg';
import _Lens from './sprites/lens.svg';
import _Lifebuoy from './sprites/lifebuoy.svg';
import _Crosshair from './sprites/crosshair.svg';
import _ZeroConfig from './sprites/zero-config.svg';
import _Power from './sprites/power.svg';
import _Slash from './sprites/slash.svg';
import _Target from './sprites/target.svg';
import _Globe from './sprites/globe.svg';
import _BlendMode from './sprites/blend-mode.svg';
import _Alpha from './sprites/alpha.svg';
import _ChartBarRandom from './sprites/chart-bar-random.svg';
import _ChartBarMiddle from './sprites/chart-bar-middle.svg';
import _ChartBarPeak from './sprites/chart-bar-peak.svg';
import _ChartPie from './sprites/chart-pie.svg';
import _ChartTrendingUp from './sprites/chart-trending-up.svg';
import _ChartTrendingDown from './sprites/chart-trending-down.svg';
import _ChartActivity from './sprites/chart-activity.svg';
import _Analytics from './sprites/analytics.svg';
import _Monitoring from './sprites/monitoring.svg';
import _CheckCircle from './sprites/check-circle.svg';
import _Check from './sprites/check.svg';
import _DoubleCheck from './sprites/double-check.svg';
import _CheckSquare from './sprites/check-square.svg';
import _CheckSquareFill from './sprites/check-square-fill.svg';
import _CheckCircleFill from './sprites/check-circle-fill.svg';
import _Question from './sprites/question.svg';
import _QuestionFill from './sprites/question-fill.svg';
import _Information from './sprites/information.svg';
import _Dollar from './sprites/dollar.svg';
import _V0CreditsV from './sprites/v0-credits-v.svg';
import _V0Credits0 from './sprites/v0-credits-0.svg';
import _DollarFill from './sprites/dollar-fill.svg';
import _InformationFill from './sprites/information-fill.svg';
import _InformationFillSmall from './sprites/information-fill-small.svg';
import _Warning from './sprites/warning.svg';
import _WarningFill from './sprites/warning-fill.svg';
import _Stop from './sprites/stop.svg';
import _StopFillSmall from './sprites/stop-fill-small.svg';
import _StopFill from './sprites/stop-fill.svg';
import _Status from './sprites/status.svg';
import _StatusSmall from './sprites/status-small.svg';
import _ChevronUp from './sprites/chevron-up.svg';
import _ChevronRight from './sprites/chevron-right.svg';
import _ChevronDown from './sprites/chevron-down.svg';
import _ChevronLeft from './sprites/chevron-left.svg';
import _ChevronUpSmall from './sprites/chevron-up-small.svg';
import _ChevronRightSmall from './sprites/chevron-right-small.svg';
import _ChevronDownSmall from './sprites/chevron-down-small.svg';
import _ChevronLeftSmall from './sprites/chevron-left-small.svg';
import _ChevronDoubleUp from './sprites/chevron-double-up.svg';
import _ChevronDoubleRight from './sprites/chevron-double-right.svg';
import _ChevronDoubleDown from './sprites/chevron-double-down.svg';
import _ChevronDoubleLeft from './sprites/chevron-double-left.svg';
import _ChevronUpDown from './sprites/chevron-up-down.svg';
import _ChevronCircleUpFill from './sprites/chevron-circle-up-fill.svg';
import _ChevronCircleRight from './sprites/chevron-circle-right.svg';
import _ChevronCircleUp from './sprites/chevron-circle-up.svg';
import _ChevronCircleRightFill from './sprites/chevron-circle-right-fill.svg';
import _ChevronCircleDown from './sprites/chevron-circle-down.svg';
import _ChevronCircleDownFill from './sprites/chevron-circle-down-fill.svg';
import _ChevronCircleLeft from './sprites/chevron-circle-left.svg';
import _ChevronCircleLeftFill from './sprites/chevron-circle-left-fill.svg';
import _ArrowRight from './sprites/arrow-right.svg';
import _ArrowLeftRight from './sprites/arrow-left-right.svg';
import _ArrowMove from './sprites/arrow-move.svg';
import _ArrowMoveUnread from './sprites/arrow-move-unread.svg';
import _ArrowUpDown from './sprites/arrow-up-down.svg';
import _ArrowDown from './sprites/arrow-down.svg';
import _ArrowLeft from './sprites/arrow-left.svg';
import _ArrowUp from './sprites/arrow-up.svg';
import _ArrowCircleUp from './sprites/arrow-circle-up.svg';
import _ArrowCircleRight from './sprites/arrow-circle-right.svg';
import _ArrowCircleDown from './sprites/arrow-circle-down.svg';
import _ArrowCircleLeft from './sprites/arrow-circle-left.svg';
import _CornerDownRight from './sprites/corner-down-right.svg';
import _CornerUpRight from './sprites/corner-up-right.svg';
import _CornerLeftDown from './sprites/corner-left-down.svg';
import _CornerLeftUp from './sprites/corner-left-up.svg';
import _CornerRightDown from './sprites/corner-right-down.svg';
import _CornerDownLeft from './sprites/corner-down-left.svg';
import _CornerRightUp from './sprites/corner-right-up.svg';
import _CornerUpLeft from './sprites/corner-up-left.svg';
import _ArrowUpRight from './sprites/arrow-up-right.svg';
import _ArrowCrossed from './sprites/arrow-crossed.svg';
import _ArrowUpRightSmall from './sprites/arrow-up-right-small.svg';
import _ArrowUpDiagonalScale from './sprites/arrow-up-diagonal-scale.svg';
import _ArrowUpDiagonalScaleSmall from './sprites/arrow-up-diagonal-scale-small.svg';
import _RotateClockwise from './sprites/rotate-clockwise.svg';
import _RotateCounterClockwise from './sprites/rotate-counter-clockwise.svg';
import _RefreshClockwise from './sprites/refresh-clockwise.svg';
import _RefreshCounterClockwise from './sprites/refresh-counter-clockwise.svg';
import _ArrowDownRight from './sprites/arrow-down-right.svg';
import _External from './sprites/external.svg';
import _ShareAlt1 from './sprites/share-alt-1.svg';
import _ShareAlt2 from './sprites/share-alt-2.svg';
import _ExternalSmall from './sprites/external-small.svg';
import _Share from './sprites/share.svg';
import _ShareAlt3 from './sprites/share-alt-3.svg';
import _Download from './sprites/download.svg';
import _SignIn from './sprites/sign-in.svg';
import _LogOut from './sprites/log-out.svg';
import _Inspect from './sprites/inspect.svg';
import _ArrowDownLeft from './sprites/arrow-down-left.svg';
import _ArrowUpLeft from './sprites/arrow-up-left.svg';
import _Menu from './sprites/menu.svg';
import _MenuAlt from './sprites/menu-alt.svg';
import _ListFilter from './sprites/list-filter.svg';
import _MenuAltUnread from './sprites/menu-alt-unread.svg';
import _Logs from './sprites/logs.svg';
import _ListUnordered from './sprites/list-unordered.svg';
import _ListOrdered from './sprites/list-ordered.svg';
import _AlignmentLeft from './sprites/alignment-left.svg';
import _SortAscending from './sprites/sort-ascending.svg';
import _SortDescending from './sprites/sort-descending.svg';
import _AlignmentCenter from './sprites/alignment-center.svg';
import _AlignmentRight from './sprites/alignment-right.svg';
import _GridSquare from './sprites/grid-square.svg';
import _GridMasonry from './sprites/grid-masonry.svg';
import _Copy from './sprites/copy.svg';
import _BrowserSafari from './sprites/browser-safari.svg';
import _BrowserArc from './sprites/browser-arc.svg';
import _BrowserFirefox from './sprites/browser-firefox.svg';
import _BrowserEdge from './sprites/browser-edge.svg';
import _BrowserOpera from './sprites/browser-opera.svg';
import _BrowserBrave from './sprites/browser-brave.svg';
import _BrowserChrome from './sprites/browser-chrome.svg';
import _AcronymJson from './sprites/acronym-json.svg';
import _HalfCircle from './sprites/half-circle.svg';
import _IncognitoAlt from './sprites/incognito-alt.svg';
import _Incognito from './sprites/incognito.svg';
import _Robot from './sprites/robot.svg';
import _Fingerprint from './sprites/fingerprint.svg';
import _AcronymIsr from './sprites/acronym-isr.svg';
import _WindowCritical from './sprites/window-critical.svg';
import _WindowAdvanced from './sprites/window-advanced.svg';
import _LogoVercelLightSvg from './variants/logo-vercel/logo-vercel-light.svg';
import _LogoVercelDarkSvg from './variants/logo-vercel/logo-vercel-dark.svg';
import _LogoVercelFillLightSvg from './variants/logo-vercel-fill/logo-vercel-fill-light.svg';
import _LogoVercelFillDarkSvg from './variants/logo-vercel-fill/logo-vercel-fill-dark.svg';
import _LogoVercelCircleLightSvg from './variants/logo-vercel-circle/logo-vercel-circle-light.svg';
import _LogoVercelCircleDarkSvg from './variants/logo-vercel-circle/logo-vercel-circle-dark.svg';
import _LogoAcmeLightSvg from './variants/logo-acme/logo-acme-light.svg';
import _LogoAcmeDarkSvg from './variants/logo-acme/logo-acme-dark.svg';
import _LogoGeistLightSvg from './variants/logo-geist/logo-geist-light.svg';
import _LogoGeistDarkSvg from './variants/logo-geist/logo-geist-dark.svg';
import _LogoVercelApiLightSvg from './variants/logo-vercel-api/logo-vercel-api-light.svg';
import _LogoVercelApiDarkSvg from './variants/logo-vercel-api/logo-vercel-api-dark.svg';
import _LogoNextLightSvg from './variants/logo-next/logo-next-light.svg';
import _LogoNextDarkSvg from './variants/logo-next/logo-next-dark.svg';
import _LogoTurborepoColorLightSvg from './variants/logo-turborepo/logo-turborepo-color-light.svg';
import _LogoTurborepoColorDarkSvg from './variants/logo-turborepo/logo-turborepo-color-dark.svg';
import _LogoTurborepoLightSvg from './variants/logo-turborepo/logo-turborepo-light.svg';
import _LogoTurborepoDarkSvg from './variants/logo-turborepo/logo-turborepo-dark.svg';
import _LogoTurbopackColorLightSvg from './variants/logo-turbopack/logo-turbopack-color-light.svg';
import _LogoTurbopackColorDarkSvg from './variants/logo-turbopack/logo-turbopack-color-dark.svg';
import _LogoTurbopackLightSvg from './variants/logo-turbopack/logo-turbopack-light.svg';
import _LogoTurbopackDarkSvg from './variants/logo-turbopack/logo-turbopack-dark.svg';
import _LogoHyperColorLightSvg from './variants/logo-hyper/logo-hyper-color-light.svg';
import _LogoHyperColorDarkSvg from './variants/logo-hyper/logo-hyper-color-dark.svg';
import _LogoHyperLightSvg from './variants/logo-hyper/logo-hyper-light.svg';
import _LogoHyperDarkSvg from './variants/logo-hyper/logo-hyper-dark.svg';
import _LogoV0LightSvg from './variants/logo-v0/logo-v0-light.svg';
import _LogoV0DarkSvg from './variants/logo-v0/logo-v0-dark.svg';
import _LogoV0WideLightSvg from './variants/logo-v0-wide/logo-v0-wide-light.svg';
import _LogoV0WideDarkSvg from './variants/logo-v0-wide/logo-v0-wide-dark.svg';
import _LogoTwitterColorLightSvg from './variants/logo-twitter/logo-twitter-color-light.svg';
import _LogoTwitterColorDarkSvg from './variants/logo-twitter/logo-twitter-color-dark.svg';
import _LogoTwitterLightSvg from './variants/logo-twitter/logo-twitter-light.svg';
import _LogoTwitterDarkSvg from './variants/logo-twitter/logo-twitter-dark.svg';
import _LogoTwitterXLightSvg from './variants/logo-twitter-x/logo-twitter-x-light.svg';
import _LogoTwitterXDarkSvg from './variants/logo-twitter-x/logo-twitter-x-dark.svg';
import _LogoTwitterXSmallLightSvg from './variants/logo-twitter-x-small/logo-twitter-x-small-light.svg';
import _LogoTwitterXSmallDarkSvg from './variants/logo-twitter-x-small/logo-twitter-x-small-dark.svg';
import _LogoGithubLightSvg from './variants/logo-github/logo-github-light.svg';
import _LogoGithubDarkSvg from './variants/logo-github/logo-github-dark.svg';
import _LogoGithubSmallLightSvg from './variants/logo-github-small/logo-github-small-light.svg';
import _LogoGithubSmallDarkSvg from './variants/logo-github-small/logo-github-small-dark.svg';
import _LogoBitbucketColorLightSvg from './variants/logo-bitbucket/logo-bitbucket-color-light.svg';
import _LogoBitbucketColorDarkSvg from './variants/logo-bitbucket/logo-bitbucket-color-dark.svg';
import _LogoBitbucketLightSvg from './variants/logo-bitbucket/logo-bitbucket-light.svg';
import _LogoBitbucketDarkSvg from './variants/logo-bitbucket/logo-bitbucket-dark.svg';
import _LogoGitlabColorLightSvg from './variants/logo-gitlab/logo-gitlab-color-light.svg';
import _LogoGitlabColorDarkSvg from './variants/logo-gitlab/logo-gitlab-color-dark.svg';
import _LogoGitlabLightSvg from './variants/logo-gitlab/logo-gitlab-light.svg';
import _LogoGitlabDarkSvg from './variants/logo-gitlab/logo-gitlab-dark.svg';
import _LogoSvelteColorLightSvg from './variants/logo-svelte/logo-svelte-color-light.svg';
import _LogoSvelteColorDarkSvg from './variants/logo-svelte/logo-svelte-color-dark.svg';
import _LogoSvelteLightSvg from './variants/logo-svelte/logo-svelte-light.svg';
import _LogoSvelteDarkSvg from './variants/logo-svelte/logo-svelte-dark.svg';
import _LogoReactColorLightSvg from './variants/logo-react/logo-react-color-light.svg';
import _LogoReactColorDarkSvg from './variants/logo-react/logo-react-color-dark.svg';
import _LogoReactLightSvg from './variants/logo-react/logo-react-light.svg';
import _LogoReactDarkSvg from './variants/logo-react/logo-react-dark.svg';
import _LogoNuxtColorLightSvg from './variants/logo-nuxt/logo-nuxt-color-light.svg';
import _LogoNuxtColorDarkSvg from './variants/logo-nuxt/logo-nuxt-color-dark.svg';
import _LogoNuxtLightSvg from './variants/logo-nuxt/logo-nuxt-light.svg';
import _LogoNuxtDarkSvg from './variants/logo-nuxt/logo-nuxt-dark.svg';
import _LogoRemixLightSvg from './variants/logo-remix/logo-remix-light.svg';
import _LogoRemixDarkSvg from './variants/logo-remix/logo-remix-dark.svg';
import _LogoLinearColorLightSvg from './variants/logo-linear/logo-linear-color-light.svg';
import _LogoLinearColorDarkSvg from './variants/logo-linear/logo-linear-color-dark.svg';
import _LogoLinearLightSvg from './variants/logo-linear/logo-linear-light.svg';
import _LogoLinearDarkSvg from './variants/logo-linear/logo-linear-dark.svg';
import _LogoSlackColorLightSvg from './variants/logo-slack/logo-slack-color-light.svg';
import _LogoSlackColorDarkSvg from './variants/logo-slack/logo-slack-color-dark.svg';
import _LogoSlackLightSvg from './variants/logo-slack/logo-slack-light.svg';
import _LogoSlackDarkSvg from './variants/logo-slack/logo-slack-dark.svg';
import _LogoDiscordColorLightSvg from './variants/logo-discord/logo-discord-color-light.svg';
import _LogoDiscordColorDarkSvg from './variants/logo-discord/logo-discord-color-dark.svg';
import _LogoDiscordLightSvg from './variants/logo-discord/logo-discord-light.svg';
import _LogoDiscordDarkSvg from './variants/logo-discord/logo-discord-dark.svg';
import _LogoSolidstartColorLightSvg from './variants/logo-solidstart/logo-solidstart-color-light.svg';
import _LogoSolidstartColorDarkSvg from './variants/logo-solidstart/logo-solidstart-color-dark.svg';
import _LogoSolidstartLightSvg from './variants/logo-solidstart/logo-solidstart-light.svg';
import _LogoSolidstartDarkSvg from './variants/logo-solidstart/logo-solidstart-dark.svg';
import _LogoViteColorLightSvg from './variants/logo-vite/logo-vite-color-light.svg';
import _LogoViteColorDarkSvg from './variants/logo-vite/logo-vite-color-dark.svg';
import _LogoViteLightSvg from './variants/logo-vite/logo-vite-light.svg';
import _LogoViteDarkSvg from './variants/logo-vite/logo-vite-dark.svg';
import _LogoVueColorLightSvg from './variants/logo-vue/logo-vue-color-light.svg';
import _LogoVueColorDarkSvg from './variants/logo-vue/logo-vue-color-dark.svg';
import _LogoVueLightSvg from './variants/logo-vue/logo-vue-light.svg';
import _LogoVueDarkSvg from './variants/logo-vue/logo-vue-dark.svg';
import _LogoGatsbyColorLightSvg from './variants/logo-gatsby/logo-gatsby-color-light.svg';
import _LogoGatsbyColorDarkSvg from './variants/logo-gatsby/logo-gatsby-color-dark.svg';
import _LogoGatsbyLightSvg from './variants/logo-gatsby/logo-gatsby-light.svg';
import _LogoGatsbyDarkSvg from './variants/logo-gatsby/logo-gatsby-dark.svg';
import _LogoNodeColorLightSvg from './variants/logo-node/logo-node-color-light.svg';
import _LogoNodeColorDarkSvg from './variants/logo-node/logo-node-color-dark.svg';
import _LogoNodeLightSvg from './variants/logo-node/logo-node-light.svg';
import _LogoNodeDarkSvg from './variants/logo-node/logo-node-dark.svg';
import _LogoRustLightSvg from './variants/logo-rust/logo-rust-light.svg';
import _LogoRustDarkSvg from './variants/logo-rust/logo-rust-dark.svg';
import _LogoOpenaiLightSvg from './variants/logo-openai/logo-openai-light.svg';
import _LogoOpenaiDarkSvg from './variants/logo-openai/logo-openai-dark.svg';
import _LogoOktaLightSvg from './variants/logo-okta/logo-okta-light.svg';
import _LogoOktaDarkSvg from './variants/logo-okta/logo-okta-dark.svg';
import _LogoLaunchdarklyLightSvg from './variants/logo-launchdarkly/logo-launchdarkly-light.svg';
import _LogoLaunchdarklyDarkSvg from './variants/logo-launchdarkly/logo-launchdarkly-dark.svg';
import _LogoPythonColorLightSvg from './variants/logo-python/logo-python-color-light.svg';
import _LogoPythonColorDarkSvg from './variants/logo-python/logo-python-color-dark.svg';
import _LogoPythonLightSvg from './variants/logo-python/logo-python-light.svg';
import _LogoPythonDarkSvg from './variants/logo-python/logo-python-dark.svg';
import _LogoEmberColorLightSvg from './variants/logo-ember/logo-ember-color-light.svg';
import _LogoEmberColorDarkSvg from './variants/logo-ember/logo-ember-color-dark.svg';
import _LogoEmberLightSvg from './variants/logo-ember/logo-ember-light.svg';
import _LogoEmberDarkSvg from './variants/logo-ember/logo-ember-dark.svg';
import _LogoAngularColorLightSvg from './variants/logo-angular/logo-angular-color-light.svg';
import _LogoAngularColorDarkSvg from './variants/logo-angular/logo-angular-color-dark.svg';
import _LogoAngularLightSvg from './variants/logo-angular/logo-angular-light.svg';
import _LogoAngularDarkSvg from './variants/logo-angular/logo-angular-dark.svg';
import _LogoHugoColorLightSvg from './variants/logo-hugo/logo-hugo-color-light.svg';
import _LogoHugoColorDarkSvg from './variants/logo-hugo/logo-hugo-color-dark.svg';
import _LogoHugoLightSvg from './variants/logo-hugo/logo-hugo-light.svg';
import _LogoHugoDarkSvg from './variants/logo-hugo/logo-hugo-dark.svg';
import _LogoAstroColorLightSvg from './variants/logo-astro/logo-astro-color-light.svg';
import _LogoAstroColorDarkSvg from './variants/logo-astro/logo-astro-color-dark.svg';
import _LogoAstroLightSvg from './variants/logo-astro/logo-astro-light.svg';
import _LogoAstroDarkSvg from './variants/logo-astro/logo-astro-dark.svg';
import _LogoOtelColorLightSvg from './variants/logo-otel/logo-otel-color-light.svg';
import _LogoOtelColorDarkSvg from './variants/logo-otel/logo-otel-color-dark.svg';
import _LogoOtelLightSvg from './variants/logo-otel/logo-otel-light.svg';
import _LogoOtelDarkSvg from './variants/logo-otel/logo-otel-dark.svg';
import _LogoLinkedinColorLightSvg from './variants/logo-linkedin/logo-linkedin-color-light.svg';
import _LogoLinkedinColorDarkSvg from './variants/logo-linkedin/logo-linkedin-color-dark.svg';
import _LogoLinkedinLightSvg from './variants/logo-linkedin/logo-linkedin-light.svg';
import _LogoLinkedinDarkSvg from './variants/logo-linkedin/logo-linkedin-dark.svg';
import _LogoLinkedinSmallLightSvg from './variants/logo-linkedin-small/logo-linkedin-small-light.svg';
import _LogoLinkedinSmallDarkSvg from './variants/logo-linkedin-small/logo-linkedin-small-dark.svg';
import _LogoJiraColorLightSvg from './variants/logo-jira/logo-jira-color-light.svg';
import _LogoJiraColorDarkSvg from './variants/logo-jira/logo-jira-color-dark.svg';
import _LogoJiraLightSvg from './variants/logo-jira/logo-jira-light.svg';
import _LogoJiraDarkSvg from './variants/logo-jira/logo-jira-dark.svg';
import _LogoMetaColorLightSvg from './variants/logo-meta/logo-meta-color-light.svg';
import _LogoMetaColorDarkSvg from './variants/logo-meta/logo-meta-color-dark.svg';
import _LogoMetaLightSvg from './variants/logo-meta/logo-meta-light.svg';
import _LogoMetaDarkSvg from './variants/logo-meta/logo-meta-dark.svg';
import _LogoAzureDevopsColorLightSvg from './variants/logo-azure-devops/logo-azure-devops-color-light.svg';
import _LogoAzureDevopsColorDarkSvg from './variants/logo-azure-devops/logo-azure-devops-color-dark.svg';
import _LogoAzureDevopsLightSvg from './variants/logo-azure-devops/logo-azure-devops-light.svg';
import _LogoAzureDevopsDarkSvg from './variants/logo-azure-devops/logo-azure-devops-dark.svg';
import _LogoSitecoreXmcloudColorLightSvg from './variants/logo-sitecore-xmcloud/logo-sitecore-xmcloud-color-light.svg';
import _LogoSitecoreXmcloudColorDarkSvg from './variants/logo-sitecore-xmcloud/logo-sitecore-xmcloud-color-dark.svg';
import _LogoSitecoreXmcloudLightSvg from './variants/logo-sitecore-xmcloud/logo-sitecore-xmcloud-light.svg';
import _LogoSitecoreXmcloudDarkSvg from './variants/logo-sitecore-xmcloud/logo-sitecore-xmcloud-dark.svg';
import _LogoMongodbColorLightSvg from './variants/logo-mongodb/logo-mongodb-color-light.svg';
import _LogoMongodbColorDarkSvg from './variants/logo-mongodb/logo-mongodb-color-dark.svg';
import _LogoMongodbLightSvg from './variants/logo-mongodb/logo-mongodb-light.svg';
import _LogoMongodbDarkSvg from './variants/logo-mongodb/logo-mongodb-dark.svg';
import _LogoBigcommerceColorLightSvg from './variants/logo-bigcommerce/logo-bigcommerce-color-light.svg';
import _LogoBigcommerceColorDarkSvg from './variants/logo-bigcommerce/logo-bigcommerce-color-dark.svg';
import _LogoBigcommerceLightSvg from './variants/logo-bigcommerce/logo-bigcommerce-light.svg';
import _LogoBigcommerceDarkSvg from './variants/logo-bigcommerce/logo-bigcommerce-dark.svg';
import _LogoSalesforceColorLightSvg from './variants/logo-salesforce/logo-salesforce-color-light.svg';
import _LogoSalesforceColorDarkSvg from './variants/logo-salesforce/logo-salesforce-color-dark.svg';
import _LogoSalesforceLightSvg from './variants/logo-salesforce/logo-salesforce-light.svg';
import _LogoSalesforceDarkSvg from './variants/logo-salesforce/logo-salesforce-dark.svg';
import _LogoShopifyColorLightSvg from './variants/logo-shopify/logo-shopify-color-light.svg';
import _LogoShopifyColorDarkSvg from './variants/logo-shopify/logo-shopify-color-dark.svg';
import _LogoShopifyLightSvg from './variants/logo-shopify/logo-shopify-light.svg';
import _LogoShopifyDarkSvg from './variants/logo-shopify/logo-shopify-dark.svg';
import _LogoOptimizelyColorLightSvg from './variants/logo-optimizely/logo-optimizely-color-light.svg';
import _LogoOptimizelyColorDarkSvg from './variants/logo-optimizely/logo-optimizely-color-dark.svg';
import _LogoOptimizelyLightSvg from './variants/logo-optimizely/logo-optimizely-light.svg';
import _LogoOptimizelyDarkSvg from './variants/logo-optimizely/logo-optimizely-dark.svg';
import _LogoWordpressColorLightSvg from './variants/logo-wordpress/logo-wordpress-color-light.svg';
import _LogoWordpressColorDarkSvg from './variants/logo-wordpress/logo-wordpress-color-dark.svg';
import _LogoWordpressLightSvg from './variants/logo-wordpress/logo-wordpress-light.svg';
import _LogoWordpressDarkSvg from './variants/logo-wordpress/logo-wordpress-dark.svg';
import _LogoStripeColorLightSvg from './variants/logo-stripe/logo-stripe-color-light.svg';
import _LogoStripeColorDarkSvg from './variants/logo-stripe/logo-stripe-color-dark.svg';
import _LogoStripeLightSvg from './variants/logo-stripe/logo-stripe-light.svg';
import _LogoStripeDarkSvg from './variants/logo-stripe/logo-stripe-dark.svg';
import _LogoFacebookColorLightSvg from './variants/logo-facebook/logo-facebook-color-light.svg';
import _LogoFacebookColorDarkSvg from './variants/logo-facebook/logo-facebook-color-dark.svg';
import _LogoFacebookLightSvg from './variants/logo-facebook/logo-facebook-light.svg';
import _LogoFacebookDarkSvg from './variants/logo-facebook/logo-facebook-dark.svg';
import _LogoNewRelicColorLightSvg from './variants/logo-new-relic/logo-new-relic-color-light.svg';
import _LogoNewRelicColorDarkSvg from './variants/logo-new-relic/logo-new-relic-color-dark.svg';
import _LogoNewRelicDarkSvg from './variants/logo-new-relic/logo-new-relic-dark.svg';
import _LogoNewRelicLightSvg from './variants/logo-new-relic/logo-new-relic-light.svg';
import _LogoDatadogColorLightSvg from './variants/logo-datadog/logo-datadog-color-light.svg';
import _LogoDatadogColorDarkSvg from './variants/logo-datadog/logo-datadog-color-dark.svg';
import _LogoDatadogLightSvg from './variants/logo-datadog/logo-datadog-light.svg';
import _LogoDatadogDarkSvg from './variants/logo-datadog/logo-datadog-dark.svg';
import _LogoChecklyColorLightSvg from './variants/logo-checkly/logo-checkly-color-light.svg';
import _LogoChecklyColorDarkSvg from './variants/logo-checkly/logo-checkly-color-dark.svg';
import _LogoChecklyLightSvg from './variants/logo-checkly/logo-checkly-light.svg';
import _LogoChecklyDarkSvg from './variants/logo-checkly/logo-checkly-dark.svg';
import _LogoWebpackColorLightSvg from './variants/logo-webpack/logo-webpack-color-light.svg';
import _LogoWebpackColorDarkSvg from './variants/logo-webpack/logo-webpack-color-dark.svg';
import _LogoWebpackLightSvg from './variants/logo-webpack/logo-webpack-light.svg';
import _LogoWebpackDarkSvg from './variants/logo-webpack/logo-webpack-dark.svg';
import _LogoFigmaColorLightSvg from './variants/logo-figma/logo-figma-color-light.svg';
import _LogoFigmaColorDarkSvg from './variants/logo-figma/logo-figma-color-dark.svg';
import _LogoFigmaLightSvg from './variants/logo-figma/logo-figma-light.svg';
import _LogoFigmaDarkSvg from './variants/logo-figma/logo-figma-dark.svg';
import _LogoGoogleColorLightSvg from './variants/logo-google/logo-google-color-light.svg';
import _LogoGoogleColorDarkSvg from './variants/logo-google/logo-google-color-dark.svg';
import _LogoGoogleLightSvg from './variants/logo-google/logo-google-light.svg';
import _LogoGoogleDarkSvg from './variants/logo-google/logo-google-dark.svg';
import _LogoYCombinatorColorLightSvg from './variants/logo-y-combinator/logo-y-combinator-color-light.svg';
import _LogoYCombinatorColorDarkSvg from './variants/logo-y-combinator/logo-y-combinator-color-dark.svg';
import _LogoYCombinatorLightSvg from './variants/logo-y-combinator/logo-y-combinator-light.svg';
import _LogoYCombinatorDarkSvg from './variants/logo-y-combinator/logo-y-combinator-dark.svg';
import _LogoRedditColorLightSvg from './variants/logo-reddit/logo-reddit-color-light.svg';
import _LogoRedditColorDarkSvg from './variants/logo-reddit/logo-reddit-color-dark.svg';
import _LogoRedditLightSvg from './variants/logo-reddit/logo-reddit-light.svg';
import _LogoRedditDarkSvg from './variants/logo-reddit/logo-reddit-dark.svg';
import _LogoSplitbeeColorLightSvg from './variants/logo-splitbee/logo-splitbee-color-light.svg';
import _LogoSplitbeeColorDarkSvg from './variants/logo-splitbee/logo-splitbee-color-dark.svg';
import _LogoSplitbeeLightSvg from './variants/logo-splitbee/logo-splitbee-light.svg';
import _LogoSplitbeeDarkSvg from './variants/logo-splitbee/logo-splitbee-dark.svg';
import _LogoContentfulColorLightSvg from './variants/logo-contentful/logo-contentful-color-light.svg';
import _LogoContentfulColorDarkSvg from './variants/logo-contentful/logo-contentful-color-dark.svg';
import _LogoContentfulLightSvg from './variants/logo-contentful/logo-contentful-light.svg';
import _LogoContentfulDarkSvg from './variants/logo-contentful/logo-contentful-dark.svg';
import _LogoSanityColorLightSvg from './variants/logo-sanity/logo-sanity-color-light.svg';
import _LogoSanityColorDarkSvg from './variants/logo-sanity/logo-sanity-color-dark.svg';
import _LogoSanityLightSvg from './variants/logo-sanity/logo-sanity-light.svg';
import _LogoSanityDarkSvg from './variants/logo-sanity/logo-sanity-dark.svg';
import _LogoRubyColorLightSvg from './variants/logo-ruby/logo-ruby-color-light.svg';
import _LogoRubyColorDarkSvg from './variants/logo-ruby/logo-ruby-color-dark.svg';
import _LogoRubyLightSvg from './variants/logo-ruby/logo-ruby-light.svg';
import _LogoRubyDarkSvg from './variants/logo-ruby/logo-ruby-dark.svg';
import _LogoGoColorLightSvg from './variants/logo-go/logo-go-color-light.svg';
import _LogoGoColorDarkSvg from './variants/logo-go/logo-go-color-dark.svg';
import _LogoGoLightSvg from './variants/logo-go/logo-go-light.svg';
import _LogoGoDarkSvg from './variants/logo-go/logo-go-dark.svg';
import _LogoDatocmsColorLightSvg from './variants/logo-datocms/logo-datocms-color-light.svg';
import _LogoDatocmsColorDarkSvg from './variants/logo-datocms/logo-datocms-color-dark.svg';
import _LogoDatocmsLightSvg from './variants/logo-datocms/logo-datocms-light.svg';
import _LogoDatocmsDarkSvg from './variants/logo-datocms/logo-datocms-dark.svg';
import _LogoAzureColorLightSvg from './variants/logo-azure/logo-azure-color-light.svg';
import _LogoAzureColorDarkSvg from './variants/logo-azure/logo-azure-color-dark.svg';
import _LogoAzureLightSvg from './variants/logo-azure/logo-azure-light.svg';
import _LogoAzureDarkSvg from './variants/logo-azure/logo-azure-dark.svg';
import _LogoGoogleCloudPlatformColorLightSvg from './variants/logo-google-cloud-platform/logo-google-cloud-platform-color-light.svg';
import _LogoGoogleCloudPlatformColorDarkSvg from './variants/logo-google-cloud-platform/logo-google-cloud-platform-color-dark.svg';
import _LogoGoogleCloudPlatformLightSvg from './variants/logo-google-cloud-platform/logo-google-cloud-platform-light.svg';
import _LogoGoogleCloudPlatformDarkSvg from './variants/logo-google-cloud-platform/logo-google-cloud-platform-dark.svg';
import _LogoAwsColorLightSvg from './variants/logo-aws/logo-aws-color-light.svg';
import _LogoAwsColorDarkSvg from './variants/logo-aws/logo-aws-color-dark.svg';
import _LogoAwsLightSvg from './variants/logo-aws/logo-aws-light.svg';
import _LogoAwsDarkSvg from './variants/logo-aws/logo-aws-dark.svg';
import _LogoWhatsappColorLightSvg from './variants/logo-whatsapp/logo-whatsapp-color-light.svg';
import _LogoWhatsappColorDarkSvg from './variants/logo-whatsapp/logo-whatsapp-color-dark.svg';
import _LogoWhatsappLightSvg from './variants/logo-whatsapp/logo-whatsapp-light.svg';
import _LogoWhatsappDarkSvg from './variants/logo-whatsapp/logo-whatsapp-dark.svg';
import _LogoFacebookMessengerColorLightSvg from './variants/logo-facebook-messenger/logo-facebook-messenger-color-light.svg';
import _LogoFacebookMessengerColorDarkSvg from './variants/logo-facebook-messenger/logo-facebook-messenger-color-dark.svg';
import _LogoFacebookMessengerLightSvg from './variants/logo-facebook-messenger/logo-facebook-messenger-light.svg';
import _LogoFacebookMessengerDarkSvg from './variants/logo-facebook-messenger/logo-facebook-messenger-dark.svg';
import _LogoAmexColorLightSvg from './variants/logo-amex/logo-amex-color-light.svg';
import _LogoAmexColorDarkSvg from './variants/logo-amex/logo-amex-color-dark.svg';
import _LogoAmexLightSvg from './variants/logo-amex/logo-amex-light.svg';
import _LogoAmexDarkSvg from './variants/logo-amex/logo-amex-dark.svg';
import _LogoVisaColorLightSvg from './variants/logo-visa/logo-visa-color-light.svg';
import _LogoVisaColorDarkSvg from './variants/logo-visa/logo-visa-color-dark.svg';
import _LogoVisaLightSvg from './variants/logo-visa/logo-visa-light.svg';
import _LogoVisaDarkSvg from './variants/logo-visa/logo-visa-dark.svg';
import _LogoMastercardColorLightSvg from './variants/logo-mastercard/logo-mastercard-color-light.svg';
import _LogoMastercardColorDarkSvg from './variants/logo-mastercard/logo-mastercard-color-dark.svg';
import _LogoMastercardLightSvg from './variants/logo-mastercard/logo-mastercard-light.svg';
import _LogoMastercardDarkSvg from './variants/logo-mastercard/logo-mastercard-dark.svg';
import _LogoYoutubeColorLightSvg from './variants/logo-youtube/logo-youtube-color-light.svg';
import _LogoYoutubeColorDarkSvg from './variants/logo-youtube/logo-youtube-color-dark.svg';
import _LogoYoutubeLightSvg from './variants/logo-youtube/logo-youtube-light.svg';
import _LogoYoutubeDarkSvg from './variants/logo-youtube/logo-youtube-dark.svg';
import _LogoRedwoodjsColorLightSvg from './variants/logo-redwoodjs/logo-redwoodjs-color-light.svg';
import _LogoRedwoodjsColorDarkSvg from './variants/logo-redwoodjs/logo-redwoodjs-color-dark.svg';
import _LogoRedwoodjsLightSvg from './variants/logo-redwoodjs/logo-redwoodjs-light.svg';
import _LogoRedwoodjsDarkSvg from './variants/logo-redwoodjs/logo-redwoodjs-dark.svg';
import _LogoSentryColorLightSvg from './variants/logo-sentry/logo-sentry-color-light.svg';
import _LogoSentryColorDarkSvg from './variants/logo-sentry/logo-sentry-color-dark.svg';
import _LogoSentryLightSvg from './variants/logo-sentry/logo-sentry-light.svg';
import _LogoSentryDarkSvg from './variants/logo-sentry/logo-sentry-dark.svg';
import _LogoYoutubeSmallLightSvg from './variants/logo-youtube-small/logo-youtube-small-light.svg';
import _LogoYoutubeSmallDarkSvg from './variants/logo-youtube-small/logo-youtube-small-dark.svg';

export const variantIcons = {
  'logo-vercel': {
    light: _LogoVercelLightSvg,
    dark: _LogoVercelDarkSvg,
  } as IconVariant,
  'logo-vercel-fill': {
    light: _LogoVercelFillLightSvg,
    dark: _LogoVercelFillDarkSvg,
  } as IconVariant,
  'logo-vercel-circle': {
    light: _LogoVercelCircleLightSvg,
    dark: _LogoVercelCircleDarkSvg,
  } as IconVariant,
  'logo-acme': {
    light: _LogoAcmeLightSvg,
    dark: _LogoAcmeDarkSvg,
  } as IconVariant,
  'logo-geist': {
    light: _LogoGeistLightSvg,
    dark: _LogoGeistDarkSvg,
  } as IconVariant,
  'logo-vercel-api': {
    light: _LogoVercelApiLightSvg,
    dark: _LogoVercelApiDarkSvg,
  } as IconVariant,
  'logo-next': {
    light: _LogoNextLightSvg,
    dark: _LogoNextDarkSvg,
  } as IconVariant,
  'logo-turborepo': {
    'color-light': _LogoTurborepoColorLightSvg,
    'color-dark': _LogoTurborepoColorDarkSvg,
    light: _LogoTurborepoLightSvg,
    dark: _LogoTurborepoDarkSvg,
  } as IconVariant,
  'logo-turbopack': {
    'color-light': _LogoTurbopackColorLightSvg,
    'color-dark': _LogoTurbopackColorDarkSvg,
    light: _LogoTurbopackLightSvg,
    dark: _LogoTurbopackDarkSvg,
  } as IconVariant,
  'logo-hyper': {
    'color-light': _LogoHyperColorLightSvg,
    'color-dark': _LogoHyperColorDarkSvg,
    light: _LogoHyperLightSvg,
    dark: _LogoHyperDarkSvg,
  } as IconVariant,
  'logo-v0': {
    light: _LogoV0LightSvg,
    dark: _LogoV0DarkSvg,
  } as IconVariant,
  'logo-v0-wide': {
    light: _LogoV0WideLightSvg,
    dark: _LogoV0WideDarkSvg,
  } as IconVariant,
  'logo-twitter': {
    'color-light': _LogoTwitterColorLightSvg,
    'color-dark': _LogoTwitterColorDarkSvg,
    light: _LogoTwitterLightSvg,
    dark: _LogoTwitterDarkSvg,
  } as IconVariant,
  'logo-twitter-x': {
    light: _LogoTwitterXLightSvg,
    dark: _LogoTwitterXDarkSvg,
  } as IconVariant,
  'logo-twitter-x-small': {
    light: _LogoTwitterXSmallLightSvg,
    dark: _LogoTwitterXSmallDarkSvg,
  } as IconVariant,
  'logo-github': {
    light: _LogoGithubLightSvg,
    dark: _LogoGithubDarkSvg,
  } as IconVariant,
  'logo-github-small': {
    light: _LogoGithubSmallLightSvg,
    dark: _LogoGithubSmallDarkSvg,
  } as IconVariant,
  'logo-bitbucket': {
    'color-light': _LogoBitbucketColorLightSvg,
    'color-dark': _LogoBitbucketColorDarkSvg,
    light: _LogoBitbucketLightSvg,
    dark: _LogoBitbucketDarkSvg,
  } as IconVariant,
  'logo-gitlab': {
    'color-light': _LogoGitlabColorLightSvg,
    'color-dark': _LogoGitlabColorDarkSvg,
    light: _LogoGitlabLightSvg,
    dark: _LogoGitlabDarkSvg,
  } as IconVariant,
  'logo-svelte': {
    'color-light': _LogoSvelteColorLightSvg,
    'color-dark': _LogoSvelteColorDarkSvg,
    light: _LogoSvelteLightSvg,
    dark: _LogoSvelteDarkSvg,
  } as IconVariant,
  'logo-react': {
    'color-light': _LogoReactColorLightSvg,
    'color-dark': _LogoReactColorDarkSvg,
    light: _LogoReactLightSvg,
    dark: _LogoReactDarkSvg,
  } as IconVariant,
  'logo-nuxt': {
    'color-light': _LogoNuxtColorLightSvg,
    'color-dark': _LogoNuxtColorDarkSvg,
    light: _LogoNuxtLightSvg,
    dark: _LogoNuxtDarkSvg,
  } as IconVariant,
  'logo-remix': {
    light: _LogoRemixLightSvg,
    dark: _LogoRemixDarkSvg,
  } as IconVariant,
  'logo-linear': {
    'color-light': _LogoLinearColorLightSvg,
    'color-dark': _LogoLinearColorDarkSvg,
    light: _LogoLinearLightSvg,
    dark: _LogoLinearDarkSvg,
  } as IconVariant,
  'logo-slack': {
    'color-light': _LogoSlackColorLightSvg,
    'color-dark': _LogoSlackColorDarkSvg,
    light: _LogoSlackLightSvg,
    dark: _LogoSlackDarkSvg,
  } as IconVariant,
  'logo-discord': {
    'color-light': _LogoDiscordColorLightSvg,
    'color-dark': _LogoDiscordColorDarkSvg,
    light: _LogoDiscordLightSvg,
    dark: _LogoDiscordDarkSvg,
  } as IconVariant,
  'logo-solidstart': {
    'color-light': _LogoSolidstartColorLightSvg,
    'color-dark': _LogoSolidstartColorDarkSvg,
    light: _LogoSolidstartLightSvg,
    dark: _LogoSolidstartDarkSvg,
  } as IconVariant,
  'logo-vite': {
    'color-light': _LogoViteColorLightSvg,
    'color-dark': _LogoViteColorDarkSvg,
    light: _LogoViteLightSvg,
    dark: _LogoViteDarkSvg,
  } as IconVariant,
  'logo-vue': {
    'color-light': _LogoVueColorLightSvg,
    'color-dark': _LogoVueColorDarkSvg,
    light: _LogoVueLightSvg,
    dark: _LogoVueDarkSvg,
  } as IconVariant,
  'logo-gatsby': {
    'color-light': _LogoGatsbyColorLightSvg,
    'color-dark': _LogoGatsbyColorDarkSvg,
    light: _LogoGatsbyLightSvg,
    dark: _LogoGatsbyDarkSvg,
  } as IconVariant,
  'logo-node': {
    'color-light': _LogoNodeColorLightSvg,
    'color-dark': _LogoNodeColorDarkSvg,
    light: _LogoNodeLightSvg,
    dark: _LogoNodeDarkSvg,
  } as IconVariant,
  'logo-rust': {
    light: _LogoRustLightSvg,
    dark: _LogoRustDarkSvg,
  } as IconVariant,
  'logo-openai': {
    light: _LogoOpenaiLightSvg,
    dark: _LogoOpenaiDarkSvg,
  } as IconVariant,
  'logo-okta': {
    light: _LogoOktaLightSvg,
    dark: _LogoOktaDarkSvg,
  } as IconVariant,
  'logo-launchdarkly': {
    light: _LogoLaunchdarklyLightSvg,
    dark: _LogoLaunchdarklyDarkSvg,
  } as IconVariant,
  'logo-python': {
    'color-light': _LogoPythonColorLightSvg,
    'color-dark': _LogoPythonColorDarkSvg,
    light: _LogoPythonLightSvg,
    dark: _LogoPythonDarkSvg,
  } as IconVariant,
  'logo-ember': {
    'color-light': _LogoEmberColorLightSvg,
    'color-dark': _LogoEmberColorDarkSvg,
    light: _LogoEmberLightSvg,
    dark: _LogoEmberDarkSvg,
  } as IconVariant,
  'logo-angular': {
    'color-light': _LogoAngularColorLightSvg,
    'color-dark': _LogoAngularColorDarkSvg,
    light: _LogoAngularLightSvg,
    dark: _LogoAngularDarkSvg,
  } as IconVariant,
  'logo-hugo': {
    'color-light': _LogoHugoColorLightSvg,
    'color-dark': _LogoHugoColorDarkSvg,
    light: _LogoHugoLightSvg,
    dark: _LogoHugoDarkSvg,
  } as IconVariant,
  'logo-astro': {
    'color-light': _LogoAstroColorLightSvg,
    'color-dark': _LogoAstroColorDarkSvg,
    light: _LogoAstroLightSvg,
    dark: _LogoAstroDarkSvg,
  } as IconVariant,
  'logo-otel': {
    'color-light': _LogoOtelColorLightSvg,
    'color-dark': _LogoOtelColorDarkSvg,
    light: _LogoOtelLightSvg,
    dark: _LogoOtelDarkSvg,
  } as IconVariant,
  'logo-linkedin': {
    'color-light': _LogoLinkedinColorLightSvg,
    'color-dark': _LogoLinkedinColorDarkSvg,
    light: _LogoLinkedinLightSvg,
    dark: _LogoLinkedinDarkSvg,
  } as IconVariant,
  'logo-linkedin-small': {
    light: _LogoLinkedinSmallLightSvg,
    dark: _LogoLinkedinSmallDarkSvg,
  } as IconVariant,
  'logo-jira': {
    'color-light': _LogoJiraColorLightSvg,
    'color-dark': _LogoJiraColorDarkSvg,
    light: _LogoJiraLightSvg,
    dark: _LogoJiraDarkSvg,
  } as IconVariant,
  'logo-meta': {
    'color-light': _LogoMetaColorLightSvg,
    'color-dark': _LogoMetaColorDarkSvg,
    light: _LogoMetaLightSvg,
    dark: _LogoMetaDarkSvg,
  } as IconVariant,
  'logo-azure-devops': {
    'color-light': _LogoAzureDevopsColorLightSvg,
    'color-dark': _LogoAzureDevopsColorDarkSvg,
    light: _LogoAzureDevopsLightSvg,
    dark: _LogoAzureDevopsDarkSvg,
  } as IconVariant,
  'logo-sitecore-xmcloud': {
    'color-light': _LogoSitecoreXmcloudColorLightSvg,
    'color-dark': _LogoSitecoreXmcloudColorDarkSvg,
    light: _LogoSitecoreXmcloudLightSvg,
    dark: _LogoSitecoreXmcloudDarkSvg,
  } as IconVariant,
  'logo-mongodb': {
    'color-light': _LogoMongodbColorLightSvg,
    'color-dark': _LogoMongodbColorDarkSvg,
    light: _LogoMongodbLightSvg,
    dark: _LogoMongodbDarkSvg,
  } as IconVariant,
  'logo-bigcommerce': {
    'color-light': _LogoBigcommerceColorLightSvg,
    'color-dark': _LogoBigcommerceColorDarkSvg,
    light: _LogoBigcommerceLightSvg,
    dark: _LogoBigcommerceDarkSvg,
  } as IconVariant,
  'logo-salesforce': {
    'color-light': _LogoSalesforceColorLightSvg,
    'color-dark': _LogoSalesforceColorDarkSvg,
    light: _LogoSalesforceLightSvg,
    dark: _LogoSalesforceDarkSvg,
  } as IconVariant,
  'logo-shopify': {
    'color-light': _LogoShopifyColorLightSvg,
    'color-dark': _LogoShopifyColorDarkSvg,
    light: _LogoShopifyLightSvg,
    dark: _LogoShopifyDarkSvg,
  } as IconVariant,
  'logo-optimizely': {
    'color-light': _LogoOptimizelyColorLightSvg,
    'color-dark': _LogoOptimizelyColorDarkSvg,
    light: _LogoOptimizelyLightSvg,
    dark: _LogoOptimizelyDarkSvg,
  } as IconVariant,
  'logo-wordpress': {
    'color-light': _LogoWordpressColorLightSvg,
    'color-dark': _LogoWordpressColorDarkSvg,
    light: _LogoWordpressLightSvg,
    dark: _LogoWordpressDarkSvg,
  } as IconVariant,
  'logo-stripe': {
    'color-light': _LogoStripeColorLightSvg,
    'color-dark': _LogoStripeColorDarkSvg,
    light: _LogoStripeLightSvg,
    dark: _LogoStripeDarkSvg,
  } as IconVariant,
  'logo-facebook': {
    'color-light': _LogoFacebookColorLightSvg,
    'color-dark': _LogoFacebookColorDarkSvg,
    light: _LogoFacebookLightSvg,
    dark: _LogoFacebookDarkSvg,
  } as IconVariant,
  'logo-new-relic': {
    'color-light': _LogoNewRelicColorLightSvg,
    'color-dark': _LogoNewRelicColorDarkSvg,
    dark: _LogoNewRelicDarkSvg,
    light: _LogoNewRelicLightSvg,
  } as IconVariant,
  'logo-datadog': {
    'color-light': _LogoDatadogColorLightSvg,
    'color-dark': _LogoDatadogColorDarkSvg,
    light: _LogoDatadogLightSvg,
    dark: _LogoDatadogDarkSvg,
  } as IconVariant,
  'logo-checkly': {
    'color-light': _LogoChecklyColorLightSvg,
    'color-dark': _LogoChecklyColorDarkSvg,
    light: _LogoChecklyLightSvg,
    dark: _LogoChecklyDarkSvg,
  } as IconVariant,
  'logo-webpack': {
    'color-light': _LogoWebpackColorLightSvg,
    'color-dark': _LogoWebpackColorDarkSvg,
    light: _LogoWebpackLightSvg,
    dark: _LogoWebpackDarkSvg,
  } as IconVariant,
  'logo-figma': {
    'color-light': _LogoFigmaColorLightSvg,
    'color-dark': _LogoFigmaColorDarkSvg,
    light: _LogoFigmaLightSvg,
    dark: _LogoFigmaDarkSvg,
  } as IconVariant,
  'logo-google': {
    'color-light': _LogoGoogleColorLightSvg,
    'color-dark': _LogoGoogleColorDarkSvg,
    light: _LogoGoogleLightSvg,
    dark: _LogoGoogleDarkSvg,
  } as IconVariant,
  'logo-y-combinator': {
    'color-light': _LogoYCombinatorColorLightSvg,
    'color-dark': _LogoYCombinatorColorDarkSvg,
    light: _LogoYCombinatorLightSvg,
    dark: _LogoYCombinatorDarkSvg,
  } as IconVariant,
  'logo-reddit': {
    'color-light': _LogoRedditColorLightSvg,
    'color-dark': _LogoRedditColorDarkSvg,
    light: _LogoRedditLightSvg,
    dark: _LogoRedditDarkSvg,
  } as IconVariant,
  'logo-splitbee': {
    'color-light': _LogoSplitbeeColorLightSvg,
    'color-dark': _LogoSplitbeeColorDarkSvg,
    light: _LogoSplitbeeLightSvg,
    dark: _LogoSplitbeeDarkSvg,
  } as IconVariant,
  'logo-contentful': {
    'color-light': _LogoContentfulColorLightSvg,
    'color-dark': _LogoContentfulColorDarkSvg,
    light: _LogoContentfulLightSvg,
    dark: _LogoContentfulDarkSvg,
  } as IconVariant,
  'logo-sanity': {
    'color-light': _LogoSanityColorLightSvg,
    'color-dark': _LogoSanityColorDarkSvg,
    light: _LogoSanityLightSvg,
    dark: _LogoSanityDarkSvg,
  } as IconVariant,
  'logo-ruby': {
    'color-light': _LogoRubyColorLightSvg,
    'color-dark': _LogoRubyColorDarkSvg,
    light: _LogoRubyLightSvg,
    dark: _LogoRubyDarkSvg,
  } as IconVariant,
  'logo-go': {
    'color-light': _LogoGoColorLightSvg,
    'color-dark': _LogoGoColorDarkSvg,
    light: _LogoGoLightSvg,
    dark: _LogoGoDarkSvg,
  } as IconVariant,
  'logo-datocms': {
    'color-light': _LogoDatocmsColorLightSvg,
    'color-dark': _LogoDatocmsColorDarkSvg,
    light: _LogoDatocmsLightSvg,
    dark: _LogoDatocmsDarkSvg,
  } as IconVariant,
  'logo-azure': {
    'color-light': _LogoAzureColorLightSvg,
    'color-dark': _LogoAzureColorDarkSvg,
    light: _LogoAzureLightSvg,
    dark: _LogoAzureDarkSvg,
  } as IconVariant,
  'logo-google-cloud-platform': {
    'color-light': _LogoGoogleCloudPlatformColorLightSvg,
    'color-dark': _LogoGoogleCloudPlatformColorDarkSvg,
    light: _LogoGoogleCloudPlatformLightSvg,
    dark: _LogoGoogleCloudPlatformDarkSvg,
  } as IconVariant,
  'logo-aws': {
    'color-light': _LogoAwsColorLightSvg,
    'color-dark': _LogoAwsColorDarkSvg,
    light: _LogoAwsLightSvg,
    dark: _LogoAwsDarkSvg,
  } as IconVariant,
  'logo-whatsapp': {
    'color-light': _LogoWhatsappColorLightSvg,
    'color-dark': _LogoWhatsappColorDarkSvg,
    light: _LogoWhatsappLightSvg,
    dark: _LogoWhatsappDarkSvg,
  } as IconVariant,
  'logo-facebook-messenger': {
    'color-light': _LogoFacebookMessengerColorLightSvg,
    'color-dark': _LogoFacebookMessengerColorDarkSvg,
    light: _LogoFacebookMessengerLightSvg,
    dark: _LogoFacebookMessengerDarkSvg,
  } as IconVariant,
  'logo-amex': {
    'color-light': _LogoAmexColorLightSvg,
    'color-dark': _LogoAmexColorDarkSvg,
    light: _LogoAmexLightSvg,
    dark: _LogoAmexDarkSvg,
  } as IconVariant,
  'logo-visa': {
    'color-light': _LogoVisaColorLightSvg,
    'color-dark': _LogoVisaColorDarkSvg,
    light: _LogoVisaLightSvg,
    dark: _LogoVisaDarkSvg,
  } as IconVariant,
  'logo-mastercard': {
    'color-light': _LogoMastercardColorLightSvg,
    'color-dark': _LogoMastercardColorDarkSvg,
    light: _LogoMastercardLightSvg,
    dark: _LogoMastercardDarkSvg,
  } as IconVariant,
  'logo-youtube': {
    'color-light': _LogoYoutubeColorLightSvg,
    'color-dark': _LogoYoutubeColorDarkSvg,
    light: _LogoYoutubeLightSvg,
    dark: _LogoYoutubeDarkSvg,
  } as IconVariant,
  'logo-redwoodjs': {
    'color-light': _LogoRedwoodjsColorLightSvg,
    'color-dark': _LogoRedwoodjsColorDarkSvg,
    light: _LogoRedwoodjsLightSvg,
    dark: _LogoRedwoodjsDarkSvg,
  } as IconVariant,
  'logo-sentry': {
    'color-light': _LogoSentryColorLightSvg,
    'color-dark': _LogoSentryColorDarkSvg,
    light: _LogoSentryLightSvg,
    dark: _LogoSentryDarkSvg,
  } as IconVariant,
  'logo-youtube-small': {
    light: _LogoYoutubeSmallLightSvg,
    dark: _LogoYoutubeSmallDarkSvg,
  } as IconVariant,
} as const;

export const spriteIcons = {
  'acronym-gif': _AcronymGif,
  'acronym-jpg': _AcronymJpg,
  'acronym-api': _AcronymApi,
  'acronym-page': _AcronymPage,
  'acronym-ppr': _AcronymPpr,
  'acronym-ssr': _AcronymSsr,
  'acronym-ssg': _AcronymSsg,
  'acronym-http': _AcronymHttp,
  'acronym-markdown': _AcronymMarkdown,
  'acronym-svg': _AcronymSvg,
  'acronym-csv': _AcronymCsv,
  'acronym-ts': _AcronymTs,
  'acronym-js': _AcronymJs,
  'face-happy': _FaceHappy,
  'face-plus': _FacePlus,
  'face-smile': _FaceSmile,
  accessibility: _Accessibility,
  'accessibility-unread': _AccessibilityUnread,
  user: _User,
  'user-check': _UserCheck,
  'user-key': _UserKey,
  'user-key-fill': _UserKeyFill,
  'user-plus': _UserPlus,
  'user-minus': _UserMinus,
  'user-settings': _UserSettings,
  'user-link': _UserLink,
  'user-screen': _UserScreen,
  eye: _Eye,
  'eye-dashed': _EyeDashed,
  'eye-off': _EyeOff,
  'user-cross': _UserCross,
  users: _Users,
  'thumb-up': _ThumbUp,
  'thumb-down': _ThumbDown,
  heart: _Heart,
  'heart-fill': _HeartFill,
  'face-unhappy': _FaceUnhappy,
  'face-meh': _FaceMeh,
  'face-sad': _FaceSad,
  'eye-all-seeing': _EyeAllSeeing,
  'device-desktop': _DeviceDesktop,
  'device-desktop-alternate': _DeviceDesktopAlternate,
  'device-desktop-alternate-small': _DeviceDesktopAlternateSmall,
  'device-phone': _DevicePhone,
  'device-tablet': _DeviceTablet,
  sun: _Sun,
  'sun-small': _SunSmall,
  'sun-alternate': _SunAlternate,
  'moon-alternate': _MoonAlternate,
  bug: _Bug,
  star: _Star,
  lightning: _Lightning,
  'star-fill': _StarFill,
  sparkles: _Sparkles,
  'cloud-upload': _CloudUpload,
  'plant-sprout': _PlantSprout,
  'cloud-download': _CloudDownload,
  cloud: _Cloud,
  moon: _Moon,
  'moon-small': _MoonSmall,
  home: _Home,
  'golden-gate': _GoldenGate,
  location: _Location,
  route: _Route,
  gps: _Gps,
  buildings: _Buildings,
  store: _Store,
  phone: _Phone,
  message: _Message,
  'message-typing': _MessageTyping,
  kv: _Kv,
  'edge-config': _EdgeConfig,
  'firewall-globe': _FirewallGlobe,
  'firewall-flame': _FirewallFlame,
  'firewall-check': _FirewallCheck,
  'ai-sdk': _AiSdk,
  postgres: _Postgres,
  blob: _Blob,
  'function-edge': _FunctionEdge,
  'function-middleware': _FunctionMiddleware,
  gauge: _Gauge,
  'preview-eye': _PreviewEye,
  'preview-document': _PreviewDocument,
  'secure-connection': _SecureConnection,
  'window-globe': _WindowGlobe,
  'arrow-globe': _ArrowGlobe,
  isr: _Isr,
  api: _Api,
  monorepo: _Monorepo,
  repositories: _Repositories,
  spiral: _Spiral,
  puzzle: _Puzzle,
  'window-variable': _WindowVariable,
  variable: _Variable,
  lambda: _Lambda,
  'layout-shift': _LayoutShift,
  'layout-shift-unread': _LayoutShiftUnread,
  function: _Function,
  'shield-globe': _ShieldGlobe,
  'globe-box': _GlobeBox,
  webhook: _Webhook,
  'function-node': _FunctionNode,
  'function-python': _FunctionPython,
  'function-ruby': _FunctionRuby,
  'function-n': _FunctionN,
  'function-go': _FunctionGo,
  'text-format': _TextFormat,
  'text-title': _TextTitle,
  'text-italic': _TextItalic,
  'text-strikethrough': _TextStrikethrough,
  'text-heading': _TextHeading,
  'text-bold': _TextBold,
  'text-uppercase': _TextUppercase,
  email: _Email,
  cursor: _Cursor,
  'cursor-head': _CursorHead,
  'cursor-click': _CursorClick,
  'code-bracket': _CodeBracket,
  'loader-circle': _LoaderCircle,
  'loader-dots': _LoaderDots,
  'terminal-window': _TerminalWindow,
  terminal: _Terminal,
  tabs: _Tabs,
  window: _Window,
  display: _Display,
  layout: _Layout,
  'sidebar-left': _SidebarLeft,
  'sidebar-right': _SidebarRight,
  header: _Header,
  footer: _Footer,
  'layout-dashed': _LayoutDashed,
  notification: _Notification,
  toolbar: _Toolbar,
  'stroke-weight': _StrokeWeight,
  'fill-square': _FillSquare,
  'bezier-curve': _BezierCurve,
  glyphs: _Glyphs,
  languages: _Languages,
  'settings-gear': _SettingsGear,
  'settings-gear-fill': _SettingsGearFill,
  'settings-sliders': _SettingsSliders,
  'settings-slider': _SettingsSlider,
  'toggle-off-alt': _ToggleOffAlt,
  'toggle-off': _ToggleOff,
  'toggle-on': _ToggleOn,
  'toggle-on-alt': _ToggleOnAlt,
  'toggle-off-alt-unread': _ToggleOffAltUnread,
  'toggle-on-alt-unread': _ToggleOnAltUnread,
  'more-horizontal': _MoreHorizontal,
  'more-vertical': _MoreVertical,
  command: _Command,
  asterisk: _Asterisk,
  diamond: _Diamond,
  'asterisk-small': _AsteriskSmall,
  'diamond-small': _DiamondSmall,
  hash: _Hash,
  percentage: _Percentage,
  plus: _Plus,
  cross: _Cross,
  'cross-circle': _CrossCircle,
  'cross-small': _CrossSmall,
  'plus-circle': _PlusCircle,
  'plus-square-small': _PlusSquareSmall,
  'minus-square-small': _MinusSquareSmall,
  minus: _Minus,
  'minus-circle': _MinusCircle,
  shift: _Shift,
  option: _Option,
  tab: _Tab,
  backspace: _Backspace,
  'slash-forward': _SlashForward,
  'slash-back': _SlashBack,
  'cross-circle-fill': _CrossCircleFill,
  router: _Router,
  servers: _Servers,
  rss: _Rss,
  delta: _Delta,
  'network-device': _NetworkDevice,
  code: _Code,
  'git-branch': _GitBranch,
  'git-branch-plus': _GitBranchPlus,
  'git-branch-minus': _GitBranchMinus,
  'beaker-flask': _BeakerFlask,
  'canary-bird': _CanaryBird,
  'git-merge': _GitMerge,
  connection: _Connection,
  'git-pull-request': _GitPullRequest,
  'git-commit': _GitCommit,
  'code-wrap': _CodeWrap,
  'code-block': _CodeBlock,
  layers: _Layers,
  'data-point': _DataPoint,
  'data-point-low': _DataPointLow,
  'data-point-medium': _DataPointMedium,
  cpu: _Cpu,
  codepen: _Codepen,
  database: _Database,
  'floppy-disk': _FloppyDisk,
  play: _Play,
  'play-fill': _PlayFill,
  pause: _Pause,
  'skip-back': _SkipBack,
  'skip-back-fill': _SkipBackFill,
  'skip-forward': _SkipForward,
  'skip-forward-fill': _SkipForwardFill,
  'stop-circle': _StopCircle,
  'rewind-10-seconds': _Rewind10Seconds,
  'forward-10-seconds-10': _Forward10Seconds10,
  'play-circle': _PlayCircle,
  fullscreen: _Fullscreen,
  'fullscreen-close': _FullscreenClose,
  'closed-captions': _ClosedCaptions,
  shareplay: _Shareplay,
  'picture-in-picture': _PictureInPicture,
  video: _Video,
  webcam: _Webcam,
  'webcam-off': _WebcamOff,
  music: _Music,
  'musical-notes': _MusicalNotes,
  microphone: _Microphone,
  'microphone-off': _MicrophoneOff,
  speaker: _Speaker,
  'speaker-fill': _SpeakerFill,
  'speaker-off': _SpeakerOff,
  'speaker-off-fill': _SpeakerOffFill,
  'speaker-volume-quiet': _SpeakerVolumeQuiet,
  'speaker-volume-quiet-fill': _SpeakerVolumeQuietFill,
  'speaker-volume-loud': _SpeakerVolumeLoud,
  'speaker-volume-loud-fill': _SpeakerVolumeLoudFill,
  crop: _Crop,
  image: _Image,
  camera: _Camera,
  'folder-closed': _FolderClosed,
  'folder-plus': _FolderPlus,
  'folder-minus': _FolderMinus,
  file: _File,
  'file-zip': _FileZip,
  'file-text': _FileText,
  invoice: _Invoice,
  'folder-open': _FolderOpen,
  key: _Key,
  'magnifying-glass': _MagnifyingGlass,
  'magnifying-glass-small': _MagnifyingGlassSmall,
  'magnifying-glass-plus': _MagnifyingGlassPlus,
  'magnifying-glass-minus': _MagnifyingGlassMinus,
  'paper-airplane': _PaperAirplane,
  'key-old': _KeyOld,
  calendar: _Calendar,
  pencil: _Pencil,
  pen: _Pen,
  'pencil-edit': _PencilEdit,
  inbox: _Inbox,
  'inbox-unread': _InboxUnread,
  archive: _Archive,
  pin: _Pin,
  paperclip: _Paperclip,
  'book-closed': _BookClosed,
  'book-open': _BookOpen,
  presentation: _Presentation,
  envelope: _Envelope,
  tag: _Tag,
  notebook: _Notebook,
  notes: _Notes,
  calculator: _Calculator,
  clipboard: _Clipboard,
  bookmark: _Bookmark,
  fulcrum: _Fulcrum,
  prism: _Prism,
  'prism-color': _PrismColor,
  compass: _Compass,
  filter: _Filter,
  'filter-small': _FilterSmall,
  wrench: _Wrench,
  trash: _Trash,
  flag: _Flag,
  'flag-priority': _FlagPriority,
  bell: _Bell,
  'bell-small': _BellSmall,
  'bell-off': _BellOff,
  briefcase: _Briefcase,
  'credit-card': _CreditCard,
  headset: _Headset,
  cart: _Cart,
  link: _Link,
  linked: _Linked,
  droplet: _Droplet,
  shield: _Shield,
  'shield-small': _ShieldSmall,
  'shield-check': _ShieldCheck,
  anchor: _Anchor,
  hook: _Hook,
  'shield-off': _ShieldOff,
  box: _Box,
  'lock-closed': _LockClosed,
  'lock-closed-small': _LockClosedSmall,
  'lock-open-small': _LockOpenSmall,
  'lock-open': _LockOpen,
  coins: _Coins,
  clock: _Clock,
  'clock-small': _ClockSmall,
  'clock-rewind': _ClockRewind,
  'clock-dashed': _ClockDashed,
  stopwatch: _Stopwatch,
  'stopwatch-unread': _StopwatchUnread,
  'stopwatch-fast': _StopwatchFast,
  lens: _Lens,
  lifebuoy: _Lifebuoy,
  crosshair: _Crosshair,
  'zero-config': _ZeroConfig,
  power: _Power,
  slash: _Slash,
  target: _Target,
  globe: _Globe,
  'blend-mode': _BlendMode,
  alpha: _Alpha,
  'chart-bar-random': _ChartBarRandom,
  'chart-bar-middle': _ChartBarMiddle,
  'chart-bar-peak': _ChartBarPeak,
  'chart-pie': _ChartPie,
  'chart-trending-up': _ChartTrendingUp,
  'chart-trending-down': _ChartTrendingDown,
  'chart-activity': _ChartActivity,
  analytics: _Analytics,
  monitoring: _Monitoring,
  'check-circle': _CheckCircle,
  check: _Check,
  'double-check': _DoubleCheck,
  'check-square': _CheckSquare,
  'check-square-fill': _CheckSquareFill,
  'check-circle-fill': _CheckCircleFill,
  question: _Question,
  'question-fill': _QuestionFill,
  information: _Information,
  dollar: _Dollar,
  'v0-credits-v': _V0CreditsV,
  'v0-credits-0': _V0Credits0,
  'dollar-fill': _DollarFill,
  'information-fill': _InformationFill,
  'information-fill-small': _InformationFillSmall,
  warning: _Warning,
  'warning-fill': _WarningFill,
  stop: _Stop,
  'stop-fill-small': _StopFillSmall,
  'stop-fill': _StopFill,
  status: _Status,
  'status-small': _StatusSmall,
  'chevron-up': _ChevronUp,
  'chevron-right': _ChevronRight,
  'chevron-down': _ChevronDown,
  'chevron-left': _ChevronLeft,
  'chevron-up-small': _ChevronUpSmall,
  'chevron-right-small': _ChevronRightSmall,
  'chevron-down-small': _ChevronDownSmall,
  'chevron-left-small': _ChevronLeftSmall,
  'chevron-double-up': _ChevronDoubleUp,
  'chevron-double-right': _ChevronDoubleRight,
  'chevron-double-down': _ChevronDoubleDown,
  'chevron-double-left': _ChevronDoubleLeft,
  'chevron-up-down': _ChevronUpDown,
  'chevron-circle-up-fill': _ChevronCircleUpFill,
  'chevron-circle-right': _ChevronCircleRight,
  'chevron-circle-up': _ChevronCircleUp,
  'chevron-circle-right-fill': _ChevronCircleRightFill,
  'chevron-circle-down': _ChevronCircleDown,
  'chevron-circle-down-fill': _ChevronCircleDownFill,
  'chevron-circle-left': _ChevronCircleLeft,
  'chevron-circle-left-fill': _ChevronCircleLeftFill,
  'arrow-right': _ArrowRight,
  'arrow-left-right': _ArrowLeftRight,
  'arrow-move': _ArrowMove,
  'arrow-move-unread': _ArrowMoveUnread,
  'arrow-up-down': _ArrowUpDown,
  'arrow-down': _ArrowDown,
  'arrow-left': _ArrowLeft,
  'arrow-up': _ArrowUp,
  'arrow-circle-up': _ArrowCircleUp,
  'arrow-circle-right': _ArrowCircleRight,
  'arrow-circle-down': _ArrowCircleDown,
  'arrow-circle-left': _ArrowCircleLeft,
  'corner-down-right': _CornerDownRight,
  'corner-up-right': _CornerUpRight,
  'corner-left-down': _CornerLeftDown,
  'corner-left-up': _CornerLeftUp,
  'corner-right-down': _CornerRightDown,
  'corner-down-left': _CornerDownLeft,
  'corner-right-up': _CornerRightUp,
  'corner-up-left': _CornerUpLeft,
  'arrow-up-right': _ArrowUpRight,
  'arrow-crossed': _ArrowCrossed,
  'arrow-up-right-small': _ArrowUpRightSmall,
  'arrow-up-diagonal-scale': _ArrowUpDiagonalScale,
  'arrow-up-diagonal-scale-small': _ArrowUpDiagonalScaleSmall,
  'rotate-clockwise': _RotateClockwise,
  'rotate-counter-clockwise': _RotateCounterClockwise,
  'refresh-clockwise': _RefreshClockwise,
  'refresh-counter-clockwise': _RefreshCounterClockwise,
  'arrow-down-right': _ArrowDownRight,
  external: _External,
  'share-alt-1': _ShareAlt1,
  'share-alt-2': _ShareAlt2,
  'external-small': _ExternalSmall,
  share: _Share,
  'share-alt-3': _ShareAlt3,
  download: _Download,
  'sign-in': _SignIn,
  'log-out': _LogOut,
  inspect: _Inspect,
  'arrow-down-left': _ArrowDownLeft,
  'arrow-up-left': _ArrowUpLeft,
  menu: _Menu,
  'menu-alt': _MenuAlt,
  'list-filter': _ListFilter,
  'menu-alt-unread': _MenuAltUnread,
  logs: _Logs,
  'list-unordered': _ListUnordered,
  'list-ordered': _ListOrdered,
  'alignment-left': _AlignmentLeft,
  'sort-ascending': _SortAscending,
  'sort-descending': _SortDescending,
  'alignment-center': _AlignmentCenter,
  'alignment-right': _AlignmentRight,
  'grid-square': _GridSquare,
  'grid-masonry': _GridMasonry,
  copy: _Copy,
  'browser-safari': _BrowserSafari,
  'browser-arc': _BrowserArc,
  'browser-firefox': _BrowserFirefox,
  'browser-edge': _BrowserEdge,
  'browser-opera': _BrowserOpera,
  'browser-brave': _BrowserBrave,
  'browser-chrome': _BrowserChrome,
  'acronym-json': _AcronymJson,
  'half-circle': _HalfCircle,
  'incognito-alt': _IncognitoAlt,
  incognito: _Incognito,
  robot: _Robot,
  fingerprint: _Fingerprint,
  'acronym-isr': _AcronymIsr,
  'window-critical': _WindowCritical,
  'window-advanced': _WindowAdvanced,
} as const;

// Do not edit this file manually. Auto-generated by geist 'pnpm generate-icons-16'.

export type SpriteIconName = keyof typeof spriteIcons;
export type VariantIconName = keyof typeof variantIcons;
export type IconName = SpriteIconName | VariantIconName;
export interface IconVariant {
  light: {
    src: string;
  };
  dark: {
    src: string;
  };
  'color-light'?: {
    src: string;
  };
  'color-dark'?: {
    src: string;
  };
}
export const iconNames = [
  ...Object.keys(spriteIcons),
  ...Object.keys(variantIcons),
] as IconName[];
